import axios from "@/axios";
import VueCookies from "vue-cookies";

const fetchClientInfo = async (clientId) => {
    let url = `/client/${clientId}/new`;
    try {
        let { data } = await axios.get(url);
        return data;
    } catch (error) {
        console.log("Error:>>>>", error);
    }
};

const initializeClient = async (payload) => {
    const url = `/client/init`;
    const { data } = await axios.post(url, payload);
    return data.data;
};

const onBoardClient = async (clientId) => {
    let res = {
        data: null,
        error: null,
    };
    const url = `/client/${clientId}/onboard`;
    try {
        const { data } = await axios.post(url);
        res.data = data;
    } catch (error) {
        res.error = error;
    }
    return res;
};

const updateClientInfo = async (clientId, payload) => {
    const url = `/client/${clientId}`;
    const { data } = await axios.patch(url, payload);
    return data;
};

// Contacts
const fetchClientContacts = async (clientId) => {
    let url = `/client/${clientId}/contact`;

    let { data } = await axios.get(url);
    return data;
};

const createAndUpdateClientContacts = async (clientId, payload) => {
    let url = `/client/${clientId}/contact`;

    let { data } = await axios.put(url, payload);
    return data;
};

// ENd Contacts

// Address
const getAddresses = async (clientId) => {
    let url = `/client/${clientId}/address`;

    let { data } = await axios.get(url);
    return data;
};

const createAndUpdateClientAddress = async (clientId, payload) => {
    let url = `/client/${clientId}/address`;

    let { data } = await axios.put(url, payload);
    return data;
};

// End Address

const getCurrencyList = async () => {
    let url = `/data/dropdown/currency`;
    let token = VueCookies.get("token");
    try {
        let { data } = await axios.get(url,{headers: {
            'Authorization':`Bearer ${token}`
          }});
        return data;
    } catch (error) {
        console.log("Error:>>>>", error);
    }
};

const getCountryList = async () => {
    let token = VueCookies.get("token");
    let url = `/data/dropdown/country`;
    try {
        let { data } = await axios.get(url,{headers: {
            'Authorization':`Bearer ${token}`
          }});
        return data;
    } catch (error) {
        console.log("Error:>>>>", error);
    }
};

const getChecksAssignedtoClient = async (clientId) => {
    const url = `/tenant-check/client-checks/${clientId}`;
    try {
        let { data } = await axios.get(url);
        return data.data;
    } catch (error) {
        console.log(error);
    }
};

const getAllClientPackages = async (clientId) => {
    const url = `/screening-package/client/${clientId}`;
    try {
        let { data } = await axios.get(url);
        return data;
    } catch (error) {
        console.log("error>>", error);
    }
};

export { fetchClientInfo, initializeClient, updateClientInfo, fetchClientContacts, createAndUpdateClientContacts, getAddresses, createAndUpdateClientAddress, getCurrencyList, onBoardClient, getChecksAssignedtoClient, getAllClientPackages, getCountryList };
