<template>
    <div class="flex flex-col flex-1 max-h-full overflow-y-auto">
        <SubHeader :showOnlyCustomCrumbs="true" :customCrumbLists="customBreadCrumbs" :handleClick="showModal" buttonText="Add Block" :showBtn="true" />
        <Table
            class="flex-1 overflow-y-auto"
            :columns="columnOptions"
            :key="pageData?.total"
            @handleClone="handleCloneBlock"
            :handleCaseClick="handleClickBlock"
            :rows="rows"
            :isLoading="loadingStates.blockList"
            @per-page-change="onPageChange($event, true)"
            @page-change="onPageChange($event)"
            :paginationOptions="paginationOptions"
            :sort-options="{
                enabled: true,
            }"
            sortingEnabled
            @sort-change="onSortChanged"
            :totalRows="pageData?.total"
            @filters-clear="clearFilters"
            @column-filter="onColumnFilters($event)"
            @search-filter="getSearchOptionsTick($event)"
            :filterOptionsAsync="columnFilterOptionsAsync"
            :filterOptions="filterOptions"
            :filters="serverParams"
        >
            <template #link="customData">
                <router-link
                    :to="{
                        name: 'block-admin-details',
                        params: { id: customData.customData.id },
                    }"
                    class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron"
                    >{{ customData.customData.name }}</router-link
                >
            </template>
            <template #show_info="customData">
                <p :class="customData.customData && customData.customData.check_count > 0 ? 'text-primary hover:text-dvbrandhoveron hover:underline cursor-pointer' : 'text-gray-500'" @click="setFieldChecks(customData.customData)">used in {{ customData.customData.check_count }} checks</p>
            </template>
        </Table>
        <AddBlockModal ref="block-admin-add-block" :isLoading="loadingStates.addingBlock" @createNewBlock="addNewBlock($event)" />
        <LoaderFull v-if="loadingStates.isCloning" loadingText="Cloning Block..." />
        <FieldChecksModal @closed="setFieldChecks(null)" :field="getFieldChecks" @handleFilterResult="handleFilterResult($event, 'check')" />
    </div>
</template>

<script>
import Table from "@shared/dashboard-components/table-large";
import SubHeader from "@/components/SubHeader";
import AddBlockModal from "../components/blockModal.vue";
import axios from "@/axios";
import LoaderFull from "@/components/loader-full";
import FieldChecksModal from "../../field-table/components/fieldChecksModal.vue";
import { onlyUnique } from "@/plugins/utils.js";

export default {
    name: "block-admin-list",
    components: {
        Table,
        SubHeader,
        AddBlockModal,
        LoaderFull,
        FieldChecksModal,
    },
    props: {},
    data() {
        return {
            customBreadCrumbs: [{ name: "Admin" }, { name: "Block List" }],
            // Remove Dummy Data After API Integration
            columns: [
                {
                    label: "Name",
                    field: "name",
                    config: {
                        type: "link",
                        filter: true,
                    },
                    tdClass: 'truncate max-w-xs',
                },
                {
                    label: "Used in checks",
                    field: "check_count",
                    sortable: false,
                    config: {
                        filter: false,
                        type: "show_info",
                    },
                    tdClass: 'truncate max-w-xs'
                },
                {
                    label: "Label",
                    field: "label",
                    config: {
                        filter: true,
                        type: 'tooltip_text',
                    },
                    tdClass: 'truncate max-w-xs'
                },
                {
                    label: "Description",
                    field: "description",
                    config: {
                        filter: false,
                    },
                    tdClass: 'truncate max-w-xs'
                },
                {
                    label: "Clone",
                    field: "clone",
                    sortable: false,
                    config: {
                        type: "clone",
                        filter: false,
                    },
                    tdClass: 'truncate max-w-xs'
                },
            ],
            rows: [],
            loadingStates: {
                blockList: false,
                addingBlock: false,
                isCloning: false,
            },
            fieldChecks: null,
            paginationOptions: {
                enabled: true,
                mode: "remote",
            },
            pageData: null,
            currentPageData: {
                page: 1,
                count: 10,
            },
            serverParams: null,
            columnFilterOptionsAsync: {},
            searchTimeout: 500,
            searchLoading: {},
            searchParams:{
                check: ""
            },
            sortParams: null
        };
    },
    computed: {
        filterOptions() {
            let result =  this.getFilterOptions(
                this.columns,
                this.rows
            )
            return result
        },
        columnOptions() {
            return this.columns.map(el => ({...el, config:{ ...el.config, isLoading: !!this.searchLoading[el.field] || false}}))
        },

        getTypeOptions() {
            let items =  this.rows.map(el => ({...el, id: el.field_type_id, name: el.ui_label}))
            console.log('items :>> ', items);
            return items
        },
        getFieldChecks(){
            return {...this.fieldChecks, checks:this.fieldChecks?.checks.filter(el => (Object.entries(el)[0][1]?.toLowerCase()?.includes(this.searchParams.check.toLowerCase())))}
        }
    },
    async mounted() {
        await this.fetchBlockLists();
    },
    methods: {
        async addNewBlock(blockData) {
            this.loadingStates.addingBlock = true;
            try {
                let url = `/blocks`;
                const response= await axios.post(url, blockData);
                if( response?.data ) {
                    this.$toast.success(response?.data || "Block created");
                    this.rows.push({ ...blockData, id: response?.data?.block_id });
                    this.$router.push({
                        name: "block-admin-details",
                        params: { id: response?.data.block_id },
                    });
                    this.$refs["block-admin-add-block"].closeBlockModal();
                }
                this.loadingStates.addingBlock = false;
            } catch (error) {
                this.$toast.error(error.response.data.message || "Failed to create block");
                this.loadingStates.addingBlock = false;
            }
            this.loadingStates.addingBlock = false;
        },
        showModal() {
            this.$refs["block-admin-add-block"].showBlockModal();
        },
        handleClickBlock(data) {
            this.$router.push({
                name: "block-admin-details",
                params: { id: data.id },
            });
        },
        // handleCloneField(row) {
        //     console.log(row, ">>>Row");
        //     this.rows.push(row);
        // },
        async fetchBlockLists() {
            this.loadingStates.blockList = true;
            try {
                let qParams = new URLSearchParams(this.currentPageData);
                let filterParams = {};
                if (this.serverParams) {
                    Object.keys(this.serverParams).map((key) => {
                        filterParams[key] = this.serverParams[key] ? this.serverParams[key]["name"] : delete filterParams[key];
                    });
                }
                let url = `/blocks?${qParams}`;
                let { data } = await axios.get(url, { params: { ...filterParams, ...this.sortParams } });
                console.log(data, "block lists");
                this.rows = data.data || [];
                this.pageData = data?.page;
            } catch (error) {
                console.log(error, ">>>>>error");
            }
            this.loadingStates.blockList = false;
        },
        async handleCloneBlock(block) {
            this.loadingStates.isCloning = true;
            try {
                let url = `/blocks/${block.id}/clone`;
                let { data } = await axios.get(url);
                this.$router.push({
                    name: "block-admin-details",
                    params: { id: data.block_id },
                });
                this.$toast.success(data.message || "Block Cloned successfully");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to clone block");
                console.log(error, ">>>error");
            }
            this.loadingStates.isCloning = false;
        },
        setFieldChecks(payload) {
            if(payload && payload.check_count > 0){
                this.fieldChecks = payload;
                this.$modal.show("fieldChecksModal");
            }
        },
        async onPageChange(data, resetPage = false) {
            this.currentPageData = {
                page: data.currentPage,
                count: data.currentPerPage,
            };
            if (resetPage) this.currentPageData.page = 1;
            await this.fetchBlockLists();
        },
        // Filter table

        async onColumnFilters({ columnFilters }) {
            this.currentPageData.page = 1
            this.serverParams = columnFilters ? { ...columnFilters } : null;
            await this.fetchBlockLists();
        },
        async onSortChanged(data) {
            console.log('data :>> ', data);
            if (data && data.length) {
                data = data[0]
                this.sortParams = {
                sort: data.field + ':' + data.type
                },
                await this.fetchBlockLists()
            }
        },
        async clearFilters() {
            this.serverParams = null;
            await this.fetchBlockLists();
        },
        getFilterOptions(columns, rows) {
            let cols = columns.reduce((result, curr) => {
                result[curr.field] = rows
                    .filter((row) => row[curr.field])
                    .map((row) => ({ id: row.id, name: row[curr.field] }))
                    .filter(onlyUnique);
                // .map((el) => ({ id: el.field_type_id ? el.field_type_id :  uuid.v4(), name: el }));
                return result;
            }, {});
            console.log("cols :>> ", cols);
            return cols;
        },

        getSearchOptionsTick(payload) {
            if (this.searchTimeout) clearTimeout(this.searchTimeout);
            const delay = 500;
            this.searchTimeout = setTimeout(() => {
                this.getSearchOptions(payload);
            }, delay);
        },

        async getSearchOptions(payload) {
            // if searching is canceled reset async filter options for the field:
            if (!payload.value) this.columnFilterOptionsAsync = null;
            else {
                // set 'loading' prop for the column we are searching in:
                this.$set(this.searchLoading, payload.column.field, true);
                console.log("searchLoading :>> ", this.searchLoading);
                try {
                    const requestData = {
                        ...this.serverParams,
                        [payload.column.query_key || payload.column.field]: payload.value,
                    };
                    const { data } = await axios.get(`/blocks`, {
                        params: requestData,
                    });
                    // set async options:
                    // const columns = {
                    //   check: this.cardData.tableLarge.checkView.columns,
                    //   candidate: this.cardData.tableLarge.candidateView.columns,
                    // };
                    this.columnFilterOptionsAsync = this.getFilterOptions(this.columnOptions, data.data);
                } catch (error) {
                    console.log("error :>> ", error);
                }

                this.$set(this.searchLoading, payload.column.field, false);
            }
        },
        handleFilterResult(data, type){
            this.searchParams[type] = data
        }

        //   End
    },
};
</script>

<style lang="scss" scoped></style>
