import { formMapper, JURISDICTION_MAP } from "@shared/utils/constants";
import store from "@/store";
import {
  TENANT_ROLES_MAP,
  DEFAULT_PACKAGE_ENTITY_TYPE
} from "@shared/utils/constants";
import { get } from "lodash";
import axios from "@/axios";
import moment from "moment";

export function getRefreeFormFields(data) {
  const getCorrectToggle = (status) => {
    // if (status) {
    if (status === true) return `correct`;
    else if (status === false) return `incorrect`;
    else return `unselected`;
    // }
  };
  const isJson = (data) => {
    try {
      var testIfJson = JSON.parse(data);
      if (typeof testIfJson == "object") {
        //Json
        return true;
      } else {
        //Not Json
        return false;
      }
    } catch (e) {
      console.log("isJson :>> ", data);
      return false;
    }
  };
  const getFormType = (fld) => {
    return formMapper[fld.field_type] || "text";
  };
  const getIcon = (ele) => {
    if (!ele.is_comment) return ele.can_approve ? "referee" : "candidate";
    else return "analyst";
  };
  const getOptions = (fld) => {
    if (fld.field_options && fld.field_options.length) {
      // if (fld.type !== 'checkbox') {
      return fld.field_options.map((el) => ({
        label: el.option_label,
        value: el.option_value,
        id: el.id,
      }));
    }
    // else return fld.field_options.map || []
    else return [];
  };
  const getValue = (form, key) => {
    if (
      ["checkbox", "date-range", "Multi select", "Checkbox"].includes(
        form.field_type
      )
    ) {
      let val;
      try {
        // if (isJs)
        val = form[key];
        val = isJson(val) ? JSON.parse(val) : val;
      } catch (error) {
        val = form[key];
        console.log("error :>> getValue", error);
      }
      if (
        (val && form.field_type === "Checkbox") ||
        form.field_type === "checkbox"
      ) {
        // in case of checkbox drop only list of values
        return (
          val.map((el) => (typeof el === "object" ? el?.option_value : el)) ||
          []
        );
      } else return val;
    } else if (form.field_type === "file" || form.field_type === "File")
      return form.file_links || [];
    else return form[key];
  };

  // as discussed with @Rohit
  const getCompareValue = (fld) => {
    if (
      [
        "File",
        "FILE",
        "Signature",
        "Consent",
        "Label",
        "label",
        "LABEL",
        "Large text",
      ].includes(fld.field_type)
    )
      return false;
    else return fld.can_approve || false;
  };

  const getComment = (ele) => {
    return ele.field_label === "Comments" ? "true" : "false";
  };
  //todo uncomment before pushing
  // * test data
  // console.log('data :>> ', data);
  return data.form_values
    .filter((lst) => !["BUTTON"].includes(lst.field_type))
    .filter((fld) => !(fld.field_type === "File" && !fld.can_approve))
    .sort((a, b) => b.serial - a.serial)
    .map((ele) => ({
      id: ele.id,
      icon: getIcon(ele),
      type: getFormType(ele) || "text",
      value: getValue(ele, "value_str"),
      label: ele.label || ele.field_label,
      placeholder: ele.placeholder,
      compare: getCompareValue(ele),
      correct: getCorrectToggle(ele.ref_input_status),
      correct_value: getValue(ele, "ref_input_value") || "",
      options: getOptions(ele),
      is_conditional: ele.is_conditional || false,
      condition: ele.condition || null,
      api_data: ele,
      is_comment: getComment(ele),
      ref_field: ele?.is_comment ? false : true,
      mandatory: ele.mandatory,
      case_reference_id: ele.case_reference_id,
      can_approve: ele?.can_approve,
      meta: ele?.meta,
    }));
}

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function getUniqueList(list) {
  return list.filter(onlyUnique);
}

export function checkColumnConfig(column) {
  if (column?.filterOptions?.columnConfig) {
    return column.filterOptions.columnConfig;
  } else {
    return { label: "name", trackBy: "id" };
  }
}

export function prePopulatedDropdownItems(columns, rows) {
  return columns.map((col) => {
    const filtered = col.filterOptions?.enabled;
    const custom_filters =
      col.filterOptions &&
      col.filterOptions.customDropdownItems &&
      col.filterOptions.customDropdownItems.length > 0;
    if (custom_filters) {
      return {
        ...col,
        filterOptions: {
          ...col.filterOptions,
          filterDropdownItems: col.filterOptions.customDropdownItems,
        },
      };
    } else {
      return {
        ...col,
        filterOptions: {
          ...col.filterOptions,
          filterDropdownItems:
            filtered &&
            removeDuplicates(
              rows
                .filter((row) => row[col.field])
                .map((row) => ({ id: row.id, name: row[col.field] }))
                .filter(onlyUnique)
            ),
        },
      };
    }
  });
}

export function checkPermission(permission) {
  //    console.log('permission :>> ', permission);
  // if (permission.includes("reportTemplates")) return true;
  return store.getters.getUserPermissions?.includes(
    get(TENANT_ROLES_MAP, permission, "")
  );
}

export function checkClientPermission(permission) {
  return store.getters.getClientPermissions?.includes(
    get(TENANT_ROLES_MAP, permission, "")
  );
}

export function getBaseUrlDelviumBackend() {
  if (process.env.VUE_APP_IS_LOCAL) {
    // Absolute url to enable local development
    return `${process.env.VUE_APP_API_BASE_URL}/api/v1`;
  } else {
    // Relative url for deployed apps
    return `/api/v1`;
  }
}

//this function for  truncate tooltip content
export function fetchLabel(data) {
  const sanitizedContent = data?.replace(/<[^>]+>/g, (match) => {
    return match?.replace(/style="[^"]*"/g, "");
  });
  return sanitizedContent && sanitizedContent?.length > 500
    ? `${sanitizedContent.substring(0, 497)}...`
    : sanitizedContent;
}

export function getBaseUrlDelviumLightray() {
  if (process.env.VUE_APP_IS_LOCAL) {
    // Absolute url to enable lightray development
    return `${process.env.VUE_APP_BACKEND_API_BASE_URL}/api/v1`;
  } else {
    // Relative url for deployed apps
    return `/api/v1`;
  }
}

function removeDuplicates(inputArray) {
  const uniqueArray = [];
  const obj = {};
  for (let val of inputArray) {
    if (val?.name && !obj[val.name]) {
      uniqueArray.push(val);
      obj[val.name] = val.name;
    }
  }
  return uniqueArray;
}

export function getParamsFromUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  const params = {};

  urlParams.forEach((value, key) => {
    params[key] = value.trim().replace(/^"(.*)"$/, "$1");
  });

  return params;
}

export const checkDefaultEntityType = (el) => {
  return el[DEFAULT_PACKAGE_ENTITY_TYPE.key] === DEFAULT_PACKAGE_ENTITY_TYPE.value
}
/**
 * 
 * @param {*} selectedList List of items to reorder
 * @param {*} fullList Full list of types where the default option can be found
 * @returns Array with the default option on the 1st position with default=true
 */
export const reorderPackageEntityTypes = (selectedList, fullList = []) => {
  if (!selectedList?.length) return []
  // collect all set types except default preselected
  const list = selectedList.filter((el) => !checkDefaultEntityType(el))

  // set preselected type from the package data or from the options if missed:
  const defaultType = selectedList.find((el) => checkDefaultEntityType(el))
    || fullList.find(checkDefaultEntityType)
  if (defaultType) {
    defaultType.default = true;
    list.unshift(defaultType);
  }

  return list;
}

export function getMappingValue(data_values = [], source, mapValues, tag) {
  const result_dict = data_values.map((ele) => {
      let list = Object.entries(mapValues);
      const result = list.reduce((accumulator, item) => {
          let keys = item[1][source].keys;
          let separator = item[1][source].separator;
          accumulator[item[0]] = keys
              .map((key) => {
                  //Iterating inside array for fetching the values in "iterable_key"
                  if (item[1][source].iterable_key) {
                      return get(ele, key, []).map((el1) => get(el1, item[1][source].iterable_key, ""));
                  }
                  if (typeof item[1][source] === "object") {
                      return get(ele, key, []);
                  }
                  return get(ele, key, "N/A");
              })
              .filter((e) => e != null && e != "")
              .join(separator ? separator : ", ");
          return accumulator;
      }, {});
      result.key = source;
      result.sourceTag = tag;
      return {...result, api_all_data: ele};
  });
  return result_dict;
}

export function getCountryName(code) {
  if (code?.length > 2) {
      let country_name = JURISDICTION_MAP.find((ele) => ele.jurisdiction.code == code.toLowerCase() && !ele.flag_code)?.jurisdiction?.full_name;
      if (country_name) {
          let flag_code = JURISDICTION_MAP.find((ele) => ele.jurisdiction.full_name == country_name && ele.jurisdiction.code !== code.toLowerCase());
          if (flag_code?.jurisdiction?.code) {
              code = flag_code?.jurisdiction?.code.toLowerCase();
          }
      }
  }
  return JURISDICTION_MAP.find((j) => j.jurisdiction.code == code)?.jurisdiction?.full_name;
}

export function csvFileName(tool, query, section) {
  let date = moment(new Date()).format("YYMMDD");
  if (query) return `${tool.replace(" ", "").toLocaleLowerCase()}_${section.replace(" ", "")}_${date.replace("-", "")}.csv`;
  else return `${tool.replace(/' '/g, "").toLocaleLowerCase()}_${section.replace(/' '/g, "")}_${date}.csv`;
}

export function convertToCSV(arr) {
  let data = JSON.parse(JSON.stringify(arr));
  let keys = [];
  if (data && data.length) {
      keys = Object.keys(data[0]);
      for (let i = 0; i < data.length; i++) {
          let row = data[i];
          for (let j = 0; j < keys.length; j++) {
              let column = keys[j];
              if (typeof row[column] === "object" && row[column]) {
                  row[column] = parseCSV(row[column], column);
              }
          }
      }
      return data;
  } else {
      // Vue.$toast.error("Error: No data to download !");
      return [];
  }
}


export function parseCSV(data, key) {
  return JSON.parse(
      JSON.stringify(
          Object.keys(data)
              .map(function(k) {
                  if (typeof data[k] === "object" && data[k]) return `${key}.${k} : ${parseCSV(data[k], `${key}.${k}`)}`;
                  else return `${key}.${k} : ${data[k]}`;
              })
              .join(";")
      )
  );
}

export async function setUsernamesDropdownData(url, data) {
  const response = await axios.post(url, data);
  try {
      if (response && response.data && response.data) {
          return "success";
      }
  } catch (error) {
      console.log(error.message);
  }
}

export async function getConfig(key) {
  let promise = new Promise((resolve, _reject) => {
      axios.post("/config", {key}).then((configResponse) => {
          resolve(configResponse.data.config);
          console.log(_reject);
      });
  });
  let result = await promise;
  return result;
}

export function getJurisdictionName(code) {
  code = code.toLowerCase() === "uk" ? "gb" : code.toLowerCase(); // Change uk jurisdiction code to gb
  return JURISDICTION_MAP.find((j) => j.jurisdiction.code == code)?.jurisdiction?.full_name;
}

export function setFavIcon(url) {
  let link = document.querySelector("link[rel~='icon']");
  const head = document.head || document.getElementsByTagName('head')[0];

  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    head.appendChild(link);
  }
  
  link.href = url || `${window.location.origin}/neo-favicon.ico`;
}

export const getEntityTypeName = (entitiesData, entity) => {
  if (!entitiesData) return null;
  const { case_entity_type_info } = entitiesData;
  return case_entity_type_info[entity.entity_type_id].name;
};

export const getShortEntityData = (entitiesData, entity) => {
  if (!(entitiesData && entity)) return null;
  return {
    id: entity.id,
    entity: entity.entity_name,
    name: entity.entity_name,
    entity_type: getEntityTypeName(entitiesData, entity),
  }
};

export const getMonitoringEntities = (entityList, entitiesData) => {
  return entityList
    .filter((entity) => {
      const entityType = getEntityTypeName(entitiesData, entity);
      return entityType !== "Case";
    })
    .map((entity) => ({
      entity: entity.entity_name,
      name: entity.entity_name,
      id: entity.id,
      entity_type: getEntityTypeName(entitiesData, entity),
      relations: [],
    }));
}


export const getEntityStatuses = (entityId) => {
  try {
      return new Promise(res => {
          axios.get(`workflow/${entityId}/status`)
              .then(e => res({ status: true, id: entityId, data: e.data?.data }))
              .catch(err => {
                  console.log(`Error fetching Entity Statuses`)
                  res({ status: false, id: entityId, error: err.message, data: [] })
              })
      })
  } catch(error) {
      console.log(error);
  }
}