<template>
    <div
        class="flex flex-col scrollToElement relative"
        :class="['form-field', {
            'form-field--consent': getCascadedFieldType(fieldData)?.customTemplate === 'consent',
            'bg-gray-100 pl-5 rounded-xl': parentData.form_entity_type != 'BLOCK',
            'consent-form-field-responsive': previewForm
        }]"
    >
        <!-- Dont show label for toggle  -->
        <!-- <div class="scroll-head absolute h-2 w-full"></div> -->
        <div class="justify-between">
            <template v-for="(field,index) in fieldData.cascading_fields">
                <FormField
                    :key="field.field_fe_id || field.field_id"
                    :ref="field"
                    class="mb-2"
                    :fieldData="field"
                    :userData="userData"
                    :parentData="parentData"
                    :path="[
                        ...path,
                        { field: 'field_fe_id', value: field.field_fe_id },
                    ]"
                    :validations="getValidation(index)"
                    :previewMode="previewMode"
                    :previewForm="previewForm"
                    :linkedBlock="linkedBlock"
                    :disabled="!field.editable || disabled || isAddReadOnly"
                    :hasSignature="hasSignature"
                    :class="{inputLabel : previewForm}"
                    :formAlreadySubmitted="formAlreadySubmitted"
                    :isCopyBlock="isCopyBlock"
                    :checkBoxId="checkBoxId"
                    :isBlockDeleted="isBlockDeleted"
                    :hidden="hidden"
                    @checkCheckboxId="checkCheckboxId"
                    @checkBoxData="checkBoxData"
                    @nextSection="nextSection"
                    @addTimeline="addTimeline"
                    @removeTimeline="removeTimeline"
                />
            </template>
        </div>
    </div>
</template>

<script>
// TODO remove unnecessary code

import { EventBus } from "@/main.js";
import store from "../store";
import {
    showCascaded,
} from "../utils";
import fieldTypes from "../utils/field-types";
const FormField = () => import("./formField.vue");

export default {
    name:"cascading-field",
    components: {
        FormField,
    },
    props: {
        parentData: {
            type: Object,
            require: true,
        },
        // receeives options data
        fieldData: {
            type: Object,
            require: true,
        },
        // optionData: { // TODO uncomment and handle properly
        //     type: Object,
        //     require: true,
        // },
        path: Array,
        // Used to determin if field can be copied. Is false when field is multi but can't be copied anymore:
        isMulti: {
            type: Boolean,
            default: false,
        },
        validations: {
            type: Object,
            default: () => null,
        },
        // is used to preview form before creating it in an admin panel:
        previewMode: {
            type: Boolean,
            default: false
        },
        selectedFieldValue: {
            type: String,
        },
        // is used to prview filled form before its submitting:
        previewForm:{
            type: Boolean,
            default: false
        },
        linkedBlock: {
            type: Object,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        userData: {
            type: Object,
            default:()=> {}
        },
        hasSignature:{
            type: Boolean
        },
        notInsideBlock:{
            type: Boolean
        },
        formAlreadySubmitted: {
            type: Boolean
        },
        isCopyBlock: {
            type: Boolean
        },
        checkBoxId: {
            type: String
        },
        isBlockDeleted: {
            type: Boolean,
        },
        isAddReadOnly:{
            type: Boolean,
        },
        hidden: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            fieldTypes,
            customTemplates: ['consent', 'label'],
            store,
            windowWidth: 0,
            windowHeight: 0,
            dateRangeOptions:[],
            value:"",
            showCascaded,
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleWindowResize);
        this.getWindowSize();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    },
    methods: {
        getCascadedFieldType(field) {
            if(field) {
                let cascadedField = this.fieldTypes[field?.field_type?.toLowerCase()] || this.fieldTypes[field?.field_base_type?.toLowerCase()];
                return cascadedField
            }
            else return ""
            
        },
        nextSection() {
            EventBus.$emit("nextSection");
        },
        addTimeline() {
            this.$emit('addTimeline');  
        },
        removeTimeline() {
          this.$emit('removeTimeline');  
        },
        handleWindowResize() {
            this.getWindowSize();
        },
        checkCheckboxId(val){
            this.$emit('checkCheckboxId',val);
        },
        getWindowSize() {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;
        },
        checkBoxData(val) {
            this.$emit('checkBoxData', val);
        },
        getValidation(index) {
            return this.currentValidations?.cascading_fields?.[index]
        },
        getCascadingValidations(field, index1, index2) {
            if (!this.validations) return null
            return this.currentValidations?.cascading_fields?.[index1]?.options?.[index2] || {}
        },
},
};
</script>

<style lang="scss">
.form-field {

&--consent{
    template{
            .template__label{
                overflow:break-word;
                word-wrap:break-word;
                hyphens:auto !important;

            }

}
}
.form-label, .consent-content {
    a {
        @apply text-primary;
        text-decoration: underline;
        cursor: pointer;
    }
        h1 {
            font-size: 2em;
        }
        h2 {
            font-size: 1.5em;
        }
        h3 {
            font-size: 1.17em;
        }
        h4 {
            font-size: 1.00em;
        }
        h5 {
            font-size: 0.83em;
        }
        h6 {
            font-size: 0.67em;
        }
    }
    ul, ol {
        padding-left: 3em;
    }
    ul {
        li {
            list-style: disc;
        }
    }
    ol {
        li {
            list-style: auto;
        }
    }
}

   


</style>
<style lang="scss" scoped>
.consentViewButton{
    background: none !important;
}
.consentError{
    border-color:rgba(220,38,38,var(--tw-border-opacity)) !important;
}

// .form-field{
//     width:100% !important;
//     margin: auto;
// }

.form-field {
    &--consent {
        .template {
            width:100%;
            .show-button{
                opacity: 0 !important;
                pointer-events: none;
            }

            &:hover {
                .show-button{
                    opacity: 1 !important;
                    pointer-events: initial;
                }
                .template__label {
                    // filter: blur(2px) !important;
                }
            }
        }
    }
}


.consent-button{
    cursor:pointer;
}

// .fade-bottom {
//     background: linear-gradient(to top, #fff 20%, transparent);
// }
::v-deep {
    input, textarea, select {
        &:not(.filepond--browser) {
            &:disabled {
                @apply opacity-80 text-gray-600;
                cursor: not-allowed;
            }
        }
    }
   
}
.neo-form-builder__info {
    min-width: 50px;
    margin: 0;
    color: black !important;
}

label {
    word-break: break-word;
}

.consentViewButton{
    margin:auto;
    text-align:center;
    & span{
      margin-top:-5%;
    }
    & span > span{
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top: -2px;
    }
}

 .mobileConsentViewButton .eye-icon{
    margin-top:1.5% !important;
}


@media (min-width: 300px) and (max-width: 500px) and (max-height:1400px){
    .mobileConsentViewButton{
        display: flex;
        //line-height: 20px;
        width: 100%;
        font-size: 15px;
        margin:0 auto auto auto;
        background-color:white;

    }
    

    .neo-form-builder__info {
        color: black !important;
        min-width: 50px;
        margin: 0;
         color: black !important;
    }
    
    label{
        width:100% !important;
    }

    
    .consent-field-block{
    margin:10px auto auto -1.7% !important;
    }
   
}

@media (min-width: 300px) and (max-width: 420px) and (max-height:1400px){
    .consent-field-block{
    margin:10px auto auto -2% !important;
    }
}
@media (max-width:1080px){
    .scrollToElement{
        // -webkit-scroll-margin-top: 4rem;
        // scroll-margin-top: 4rem;
    }
    .scroll-head{
        top: -4rem;
    }
}
@media (max-width:940px) and (max-height:1400px){
     
    .form-field{
        margin-bottom: 25px !important;
    }
.form-field {
    &--consent {
        .template {
            .show-button {
                opacity:1 !important;
                pointer-events: none;
            }
            &:hover {
                .show-button {
                    opacity: 1;
                    pointer-events: initial;
                }
                .template__label {
                    filter: none !important;
                }
            }
        }
    }
}



 .form-field .firstbtn{
       margin-top:0.1%;
    }
    .consentViewButton .eye-icon{
        margin-top:0.8%;
    }

.mobileConsentViewButton,.consentViewButton {
        display: flex;
        width: 100%;
        font-size: 15px;
        margin:0px auto auto auto !important;
        background-color:white;
        color:#0D6AD5;
    }

.consentViewButton{
    padding-top:6% !important;
}

.consent-button button{
   position:relative;
   display:flex;
   margin:auto;
}

.consent-button{
    height:20px !important;
    width:100%;
    padding:0px;
     margin: 4% auto 4% auto;
}

.consentBlock{
    display:flex;
    flex-direction:column;
    align-items:center !important;
    justify-content:center;
    margin:auto;
    cursor:pointer;
     padding:0.5rem;
    // padding: 0.5rem;
}


.consent-field-block{
    padding:3px 8px 8px 8px !important;
    // margin:10px auto auto -1.3%;
    margin: auto !important;
    align-items:center;
    justify-content:center;
    line-height:24px;
    width:100%;
    border:1px solid #DBDDF1;
}

.view-consent{
    display:flex;
}
      
}

@media screen and (min-width:769px) and (max-width:940px) and (max-height:1440px){

.consent-field-block{
    padding:3px 8px 8px 8px !important;
    margin:10px auto auto -1%;
    align-items:center;
    justify-content:center;
    line-height:24px;
    width:100%;
     border:1px solid #DBDDF1;
}
      
}

@media screen and (min-width:941px){

.eye-icon {
    color:white !important;
    z-index:10;
} 
svg {
    color:white !important;
} 


.form-field{
&--consent {
        .template {
            .show-button {
                opacity:0 !important;
            }
                .show-button{
                    opacity: 1 !important
                
                }
                .template__label {
                    // filter:blur(2px) !important;
                }
            
        }
    }
} 



.consent-field-block{
    border:1px solid #DBDDF1;
    padding:3px 8px 8px 8px !important;
    margin:auto auto auto auto;
    align-items:center;
    width:100%;
    line-height:24px;
    overflow:break-word;
    word-wrap:break-word;
    hyphens:auto;

    &:hover{
        // filter:blur(2px) !important;
    }
}



.consent-button{
    display:flex;
    position:absolute;
    width:100%;
    //margin:5% 27% 5% 32% !important;
    justify-content:center;
    height: 2.8em;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    & button{
        cursor:pointer !important;
        width:260px;
    }
}



.consent-button button{
      height:2.9em;
      display:flex;
    margin:auto;
    & .eye-icon{
        color:white !important;
        position:absolute;
     }

    & span{
        margin-top:1%;
        & span{
            margin:auto auto auto 20px;
        }
    }
}

    .consent-button button{
    position: relative;
    display: flex;
    }
    .consent-button {
    height: 10px !important;
    width: 100%;
    padding: 0px;
    position: relative;
    display: flex;
    margin: 16px auto;
    opacity: 1 !important;
    }
    .consentBlock {
    display: flex;
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
    margin: auto;
    cursor: pointer;
    padding: 0.5rem 0.5rem 0 0.5rem;
    border-radius: 10px;
    }
    .consentViewButton{
        display: flex;
        width: 100%;
        font-size: 15px;
        margin:0px auto auto 2px !important;
        background-color:white;
        color:#0D6AD5;
        padding: 0;
        height: auto !important;
        position: relative;
        background: none;
    }

    .consent-field-block{
        border: 1px solid #DBDDF1;
        padding: 6px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

    }
    .consent-button button span span{
        margin: auto auto auto 23px;
        width: 200px;
    }

.view-consent{
    display: flex;
    align-items: center;
    width: 145px;
    justify-content: space-between;
}
}

.landscape-visible{
        display: none !important;
    }

@media screen and (min-width: 600px) and (max-width: 942px) and (orientation: landscape){
    .landscape-visible{
        display: block !important;
    }
    .landscape-notVisible{
        display: none !important;
    }
    .consent-button{
        margin:2% auto !important;
    }
    .consentViewButton span{
        // margin-top: -5% !important;;
    }
    .consentViewButton{
        padding-top: 0 !important;
    }
    .consentViewButton>span{
        margin-top: 16px !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px){
    .dynamicInputFields ,.formFieldFieldSet select, .formFieldFieldSet div{
        border-radius: 10px !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 768px){
    .viewConsentBtn{
        height:20px;
    }
}

.newInput_add{
    display:none;
}
@media screen and (min-width: 360px) and (max-width: 487px){
    .consentViewButton{
        margin-top: 0% !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 359px){
    .dynamicInputFields{
        width: 190px;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 190px !important;
    }
}

@media screen and (min-width: 360px) and (max-width: 414px){
    .dynamicInputFields{
        width: 210px;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: auto !important;
        margin: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
}

@media screen and (min-width: 550px) and (max-width: 1080px) and (orientation: landscape) {
    .dynamicInputFields{
        width: 400px !important;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 400px !important;
        margin: 0;
    }
    .formFieldFieldSet div{
        padding: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
}

@media screen and (min-width: 501px) and (max-width: 768px){
    .dynamicInputFields{
        width: 400px !important;
        border:none !important;
    }
    .formFieldFieldSet select, .formFieldFieldSet div{
        width: 400px !important;
        margin: 0;
    }
    .formFieldFieldSet div{
        padding: 0;
    }
    .formFieldFieldSet input{
        margin: 0;
    }
    
}

    
</style>