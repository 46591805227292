import axios from "@/axios"
import VueCookies from "vue-cookies";
export async function getDomainTenantsList(params) {
    const url = `/delvium-admin/domain/tenants?${params}`
    let token = VueCookies.get("token");
    return await axios.get(url,{headers: {
        'Authorization':`Bearer ${token}`
    }})
}

export async function getEmailDomainTenantsList(params) {
    const url = `/delvium-admin/domain/email-domain/tenants?${params}`
    let token = VueCookies.get("token");
    return await axios.get(url,{headers: {
        'Authorization':`Bearer ${token}`
    }})
}