import SubHeader from "@/components/SubHeader";
import axios from "@/axios";
import { LicenseInfo, LecenseFeatures, AssignTenantCard } from "../components/index";
import { assignedTenantIcon, featureListIcon, licenseDetailsIcon } from "@shared/icons.js";
const ModalConfirm = () => import("@shared/modal-confirm");

export default {
    name: "licence-details",
    components: {
        SubHeader,
        LicenseInfo,
        LecenseFeatures,
        AssignTenantCard,
        ModalConfirm,
    },
    props: {},
    data: () => ({
        loadingStates: {
            fetchingDetails: false,
            submit: false,
            delete: false,
        },
        sections: [
            {
                name: "license-details",
                label: "License Details",
                disabled: false,
                component: LicenseInfo,
                show: true,
                icon: licenseDetailsIcon,
            },
            {
                name: "license-features",
                label: "License Features",
                disabled: true,
                component: LecenseFeatures,
                show: true,
                icon: featureListIcon,
            },
            {
                name: "license-tenants",
                label: "Assigned Tenants",
                disabled: true,
                component: AssignTenantCard,
                show: true,
                icon: assignedTenantIcon,
            },
        ],
        licenseData: {
            licenseDetails: {
                name: "",
                description: "",
                validity: 0,
            },
            featuresLists: [],
            licenseTenants: [],
        },
        serchValues: {
            features: "",
            tenants: "",
        },
        activeSection: null,
        customBreadCrumbs: [],
        licenseId: null,
        tenantsListsHeader: [{ name: "Tenant Name" }, { name: "No. of License" }],
    }),

    computed: {
        getActiveSection() {
            return this.activeSection || this.getSections[0];
        },
        getSections: {
            get() {
                return this.sections;
            },
            set(val) {
                this.sections = val;
            },
        },
        getFeaturedList() {
            return this.licenseData.featuresLists?.filter((el) => el.name?.toLowerCase()?.includes(this.serchValues.features.toLowerCase()));
        },
        getTenantsList() {
            return this.licenseData.licenseTenants?.filter((el) => el.title?.toLowerCase()?.includes(this.serchValues.tenants.toLowerCase()));
        },
    },
    watch: {
        $route() {
            this.updateList();
        },
    },
    async mounted() {
        this.updateList();
        this.checkActiveTab();
    },
    methods: {
        handleValidate(name, value) {
            this.licenseData.licenseDetails[name] = value;
        },
        handleAddNewTenant() {
            this.licenseData.assignedTenantLists.push({
                license_count: 1,
                id: null,
                tenant_id: null,
                title: "",
            });
        },

        updateList() {
            if (this.$route.name === "license-details") {
                this.getSections = this.getSections.map((el) => ({ ...el, disabled: false }));
            }
        },

        async sectionClick(section) {
            if (section.name === this.activeSection.name) return;
            this.activeSection = section;
            this.activeSection.disabled = false
            this.$router.push({ params: { tab: section.name } });
            this.customBreadCrumbs = [{ name: section.label }];
            await this.getAPIDetails(section.name);
            this.setBredCrumbs();
        },

        async checkActiveTab() {
            let active_tab = this.$route.params.tab;
            let query_data = this.$route.query;
            if (this.$route.name === "license-add") {
                active_tab = "license-details";
            }
            active_tab ? this.$router.push({ params: { tab: active_tab }, query: query_data }) : this.$router.push({ params: { tab: "license-details" }, query: query_data });
            let tabData = this.getSections.find((el) => el.name === active_tab);
            this.activeSection = tabData ? tabData : this.getSections[0];
            if (this.$route.name === "license-details" && this.$route.params.id) {
                this.licenseId = this.$route.params.id;
                await this.getAPIDetails(this.activeSection.name);
            }
            this.setBredCrumbs();
        },

        setBredCrumbs(crumb) {
            this.customBreadCrumbs = [];

            this.customBreadCrumbs = [{ name: this.activeSection.label }];
            if (crumb) {
                this.customBreadCrumbs.push({ name: crumb });
            }
        },

        async handleFormSubmit({ data, section }) {
            console.log(data, section, "seasdsad");
            let apiMapper = {
                "license-details": this.licenseId ? this.updateLicenseDetails : this.createLicense,
                "license-features": this.assignFeatures,
                "license-tenants": this.assignTenants,
            };
            await apiMapper[section](data);
        },

        updateSelectedSection(section) {
            let sectionIndex = this.sections.findIndex((res)=> res.name == section)
            if ((sectionIndex + 1 ) > (this.sections.length - 1)) {
                return
            }
            let nextSection = this.sections[sectionIndex + 1]
            this.sectionClick(nextSection)
        },

        async getAPIDetails(section_name) {
            let apiMapper = {
                "license-details": this.fetchLicensePackDetails,
                "license-features": this.featureData,
                "license-tenants": this.getTenantsData,
            };
            await apiMapper[section_name]();
        },

        async fetchLicensePackDetails() {
            this.loadingStates.fetchingDetails = true;
            try {
                let { data } = await axios.get(`/license-pack/${this.licenseId}/details`);
                this.licenseData.licenseDetails = data.detail || null;
            } catch (error) {
                console.log(error, "errror");
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
            this.loadingStates.fetchingDetails = false;
        },

        // Feaute Data
        async featureData() {
            this.loadingStates.fetchingDetails = true;
            this.serchValues.features = "";
            try {
                let featureLists = await this.getAllFeaturesList();
                let assignedFeature = await this.getAssignedFeatureList();
                featureLists = featureLists?.map((feature) => {
                    for (let i of assignedFeature) {
                        if (i.id === feature.id) {
                            return { ...feature, checked: true, read_only: i.read_only };
                        }
                    }
                    return { ...feature, checked: false, read_only: false };
                });
                this.licenseData.featuresLists = featureLists;
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.fetchingDetails = false;
        },
        async getAllFeaturesList() {
            try {
                let { data } = await axios.get(`/feature`);
                return data.features || [];
            } catch (error) {
                console.log(error, "errror");
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
        },
        async getAssignedFeatureList() {
            try {
                let { data } = await axios.get(`/license-pack/${this.licenseId}/feature`);
                return data.feature || [];
            } catch (error) {
                console.log(error, "errror");
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
        },
        licenseReadOnlyPermission(event) {
            this.licenseData.featuresLists = this.licenseData.featuresLists.map((el) => {
                if (el.id === event.id) {
                    return { ...el, read_only: !event.read_only };
                } else {
                    return el;
                }
            });
        },
        licensePackEvent(event) {
            this.licenseData.featuresLists = this.licenseData.featuresLists.map((el) => {
                if (el.id === event.id && !event.checked) {
                    return { ...el, read_only: event.checked };
                } else {
                    return el;
                }
            });
        },
        handleFilterResult({ data, type }) {
            this.serchValues[type] = data;
        },
        selectAllFeatures(event) {
            if (!event) {
                this.licenseData.featuresLists = this.licenseData.featuresLists.map((el) => ({ ...el, checked: event, read_only: event }));
            } else {
                this.licenseData.featuresLists = this.licenseData.featuresLists.map((el) => ({ ...el, checked: event }));
            }
        },
        async assignFeatures() {
            this.loadingStates.submit = true;
            try {
                let payload = {
                    license_pack_id: this.licenseId,
                    features: this.licenseData.featuresLists.filter((el) => el.checked).map((feature) => ({ feature_id: feature.id, read_only: feature.read_only })),
                };
                let { data } = await axios.post(`/license-pack/feature`, payload);
                this.$toast.success(data.message || "features assigned");
                this.updateSelectedSection(this.activeSection.name)
            } catch (error) {
                console.log(error, "errror");
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
            this.loadingStates.submit = false;
        },
        async updateLicenseDetails() {
            this.loadingStates.submit = true;
            try {
                let { name, description, validity } = this.licenseData.licenseDetails;
                let payload = {
                    name,
                    description,
                    validity,
                };
                let { data } = await axios.put(`/license-pack/${this.licenseId}`, payload);
                this.$toast.success(data.message || "license updated");
                this.updateSelectedSection(this.activeSection.name)
            } catch (error) {
                console.log(error, "errror");
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
            this.loadingStates.submit = false;
        },
        async createLicense() {
            this.loadingStates.submit = true;
            try {
                let { name, description, validity } = this.licenseData.licenseDetails;
                let licenseData = await axios.get("/license-pack");
                let checkLicenseData = licenseData?.data?.data?.filter(lceData => lceData?.name == name)
                if (checkLicenseData && checkLicenseData?.length) {
                    this.$toast.error('License Name Already Exists. Please try a different name.');
                    this.loadingStates.submit = false;
                    return;
                }
                let payload = {
                    name,
                    description,
                    validity,
                };
                let { data } = await axios.post(`/license-pack`, payload);
                this.licenseId = data.id
                // this.$router.push({ name: "license-details", params: { id: data.id } });
                await this.checkActiveTab();
                this.$toast.success(data.message || "license created");
                this.loadingStates.submit = false
                this.updateSelectedSection(this.activeSection.name)
            } catch (error) {
                console.log(error, "errror");
                this.loadingStates.submit = false;
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                } else {
                    this.$toast.error(error?.response?.data?.detail);
                }
            }
        },
        async deleteLicense() {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This License will be deleted Permanently. Please confirm to continue deleting this License.",
                buttonText: "Confirm",
            });
            if (promise) {
                this.loadingStates.delete = true;
                try {
                    let { data } = await axios.delete(`/license-pack/delete/${this.licenseId}`);
                    this.$router.push({ name: "license-list" });
                    this.$toast.success(data.message || "license deleted");
                    this.loadingStates.delete = false;
                } catch (error) {
                    this.loadingStates.delete = false;
                    if (Array.isArray(error?.response?.data?.detail)) {
                        this.$toast.error(error?.response?.data?.detail?.[0]?.msg);
                    } else {
                        this.$toast.error(error?.response?.data?.detail);
                    }
                    console.log(error, "errror");
                }
            }
            this.$refs["confirm-popup"].close();
        },

        // Tenant license

        async getTenantsData() {
            this.loadingStates.fetchingDetails = true;
            try {
                let tenantLists = await this.getAllTenant();
                let assignedTenantLists = await this.getAssignedTenants();
                tenantLists = tenantLists?.map((tenant) => {
                    for (let i of assignedTenantLists) {
                        if (i.id === tenant.id) {
                            return { ...tenant, checked: true, license_count: i.license_count };
                        }
                    }
                    return { ...tenant, checked: false, license_count: null };
                });
                this.licenseData.licenseTenants = tenantLists;
            } catch (error) {
                console.log(error, "errror");
            }
            this.loadingStates.fetchingDetails = false;
        },

        async getAllTenant() {
            try {
                let { data } = await axios.get("/tenant");
                return data.map((el) => ({ id: el.id, title: el.title })) || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },

        async getAssignedTenants() {
            try {
                let { data } = await axios.get(`/license-pack/${this.licenseId}/assigned_tenants`);
                return data.assigned_tenants.map((el) => ({ ...el, id: el.tenant_id })) || [];
            } catch (error) {
                console.log(error, "errror");
            }
        },
        tenantAssign(event) {
            this.licenseData.licenseTenants = this.licenseData.licenseTenants.map((el) => {
                if (el.id === event.id && !event.checked) {
                    return { ...el, license_count: null };
                } else if (el.id === event.id && event.checked) {
                    return { ...el, license_count: 1 };
                } else {
                    return { ...el };
                }
            });
        },
        selectAllTenants(event) {
            if (event) {
                this.licenseData.licenseTenants = this.licenseData.licenseTenants.map((el) => ({ ...el, checked: event, license_count: el.license_count || 1 }));
            } else {
                this.licenseData.licenseTenants = this.licenseData.licenseTenants.map((el) => ({ ...el, checked: event, license_count: null }));
            }
        },
        async assignTenants() {
            this.loadingStates.submit = true;
            try {
                let payload = {
                    license_pack_id: this.licenseId,
                    tenants: this.licenseData.licenseTenants.filter((el) => el.checked).map((tenant) => ({ tenant_id: tenant.id, license_count: tenant.license_count })),
                };
                let { data } = await axios.post(`/license-pack/allocate-tenant`, payload);
                this.$toast.success(data.message || "tenant assigned");
                this.updateSelectedSection(this.activeSection.name)
            } catch (error) {
                if (Array.isArray(error?.response?.data?.detail)) {
                    this.$toast.error(error?.response?.data?.detail?.[0]?.msg || 'Something is went wrong!')
                } 
                else {
                    this.$toast.error(error?.response?.data?.detail|| 'Something is went wrong!')
                }
            }
            this.loadingStates.submit = false;
        },
    },
};
