<template>
    <div class="flex flex-col border-r border-solid border-gray-200 m-container">
      <div class="flex justify-between align-baseline " id=header>
        <p  class="text-base-content-300 mb-0  text-sm font-bold">LTRAY-1331</p> 
      </div>
		<p class="font-semibold mb-0 text-sm capitalize"  :class="!value && 'text-gray-400' ">TBD</p>
    </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default:"",
    },
  },
};
</script>

<style>
.m-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  min-width:150px

  
}
#header{
   min-width: inherit 
  }
</style>